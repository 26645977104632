import { useContracts } from 'api/contractApi';
import { hasModifiedAffiliation } from 'components/Affiliation/storeHandler';
import { hasModifiedOnboarding } from 'components/Onboarding/storeHandler';
import { AffiliationStatusEnum } from 'types/common';
import { CountryCode } from 'types/config';

/**
 * Based on a userID returns a status of all contracts and possibly a user data.
 * If you want to check single contract, consider using the hook useContractStatus.
 *
 * @param userId NextAuth.JS session userId
 * @param isoCode
 */
const useAffiliationStatus = (userId: string | null = null, isoCode: CountryCode) => {
  let status = userId ? AffiliationStatusEnum.Loading : AffiliationStatusEnum.NotLogged;
  let onboardingContractId: string | undefined;

  const {
    data: contracts,
    error,
    mutate,
    response,
  } = useContracts(undefined, status !== AffiliationStatusEnum.NotLogged);

  if (!error && response) {
    if (contracts?.length === 0) {
      status = AffiliationStatusEnum.NotAffiliated;
    } else if (contracts.some((contract) => contract.makeOnboarding)) {
      status = AffiliationStatusEnum.Onboarding;
      onboardingContractId = contracts.find((contract) => contract.makeOnboarding)?.contract?.uid;
    } else {
      status = AffiliationStatusEnum.Affiliated;
    }
  }

  if (error) {
    status = AffiliationStatusEnum.Error; // request had to fail
  }

  if (
    userId &&
    (status === AffiliationStatusEnum.NotAffiliated ||
      status === AffiliationStatusEnum.Onboarding ||
      status === AffiliationStatusEnum.Affiliated) &&
    hasModifiedAffiliation(userId)
  ) {
    status = AffiliationStatusEnum.AffiliationDraft;
  }

  if (
    onboardingContractId &&
    status === AffiliationStatusEnum.Onboarding &&
    hasModifiedOnboarding(onboardingContractId, isoCode)
  ) {
    status = AffiliationStatusEnum.OnboardingDraft;
  }

  return {
    status,
    error,
    multiple: contracts?.length > 1,
    isEmpty: !(contracts?.length > 0),
    mutate,
    onboardingContractId,
  };
};

export default useAffiliationStatus;
