import useSWR, { useSWRConfig } from 'swr';
import { defaultSWRConfig } from 'api/useRequest';
import { fetcher } from 'utils/axios';
import useCampaignId from 'data/useCampaignId';
import { CampaignType } from 'types/campaign';

const useCurrentCampaign = (isEnabled?: boolean) => {
  const { value: campaignId, removeValue: discardCampaign } = useCampaignId();
  const key = `/campaign?id=${campaignId}`;
  const { cache } = useSWRConfig();
  const { data, error } = useSWR<CampaignType>(isEnabled && campaignId ? key : null, fetcher, {
    ...defaultSWRConfig,
    revalidateOnMount: cache.get(key) === undefined, // by default revalidation occurs on mount when initialData is not set, use this flag to force behavior
  });

  return {
    campaign: data,
    discardCampaign,
    hasCampaignId: Boolean(campaignId),
    isOK: Boolean(!error && data),
    isLoading: !error && !data,
    isError: error,
  };
};

export default useCurrentCampaign;
