import Loader from 'common/Loader';
import { discardAffiliation } from 'components/Affiliation/storeHandler';
import Landing from 'components/Landing';
import { discardOnboarding } from 'components/Onboarding/storeHandler';
import useAffiliationStatus from 'data/useAffiliationStatus';
import useConfig from 'data/useConfig';
import useSession from 'data/useSession';
import { NextPage } from 'next';
import { useCallback } from 'react';
import { AffiliationStatusEnum } from 'types/common';

const IndexPage: NextPage = () => {
  const [session] = useSession();
  const { isoCode } = useConfig();
  const { status, mutate, multiple, onboardingContractId } = useAffiliationStatus(
    session?.user?.id,
    isoCode,
  );

  const discardAffiliationHandler = useCallback(() => {
    if (session?.user?.id) {
      discardAffiliation(session?.user?.id);
      mutate(); // check if data has changed on backend
    }
  }, [mutate, session?.user?.id]);
  const discardOnboardingHandler = useCallback(() => {
    if (onboardingContractId) {
      discardOnboarding(onboardingContractId);
      mutate(); // check if data has changed on backend
    }
  }, [mutate, onboardingContractId]);

  return status === AffiliationStatusEnum.Loading ? (
    <Loader />
  ) : (
    <Landing
      contractId={onboardingContractId}
      hasMultiple={multiple}
      status={status}
      onDiscardAffiliation={discardAffiliationHandler}
      onDiscardOnboarding={discardOnboardingHandler}
    />
  );
};

export default IndexPage;
