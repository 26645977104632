import { Box, Flex, Stack } from '@chakra-ui/react';
import { Illustrations, InteractiveVariants, Notification } from '@pluxee-design-system/core';
import { px, py } from 'common/layouts/layout.styles';
import { HtmlText, TextTitle } from 'common/typography';
import CampaignLogo from 'components/Affiliation/CampaignLogo';
import OfferCard from 'components/Landing/OfferCard';
import useConfig from 'data/useConfig';
import useContractSwitcher from 'data/useContractSwitcher';
import useCurrentCampaign from 'data/useCurrentCampaign';
import { useCurrentContractId } from 'data/useCurrentContract';
import useSignIn from 'data/useSignIn';
import { I18nProps, withNamespaces } from 'i18n';
import { useCallback, useMemo } from 'react';
import gtm from 'trackers/gtm';
import { AffiliationStatusEnum } from 'types/common';
import routerPush, { getFullBasePath } from 'utils/router';

interface LandingProps extends I18nProps {
  contractId?: string;
  hasMultiple: boolean;
  status: AffiliationStatusEnum;
  onDiscardAffiliation: () => void;
  onDiscardOnboarding: () => void;
}

interface NotificationType {
  description: string;
  onClick: () => void;
  onDiscard?: () => void;
}

const singleContractHandler = () => {
  gtm.trackClickManageCompany();
  routerPush('/dashboard');
};

const affiliateHandler = () => {
  gtm.trackClickRegisterCompany();
  routerPush('/affiliation');
};

const Landing = ({
  contractId,
  hasMultiple,
  onDiscardAffiliation,
  onDiscardOnboarding,
  status,
  t,
  tHtml,
}: LandingProps) => {
  const signInHandler = useSignIn();
  const { setValue: setContractId } = useCurrentContractId();
  const { isoCode, selfcare: isSelfcareEnabled } = useConfig();
  const { campaign } = useCurrentCampaign(isoCode === 'be'); // omit condition isoCode === 'be' when cz ready

  // Permissions
  const isManageDisabled =
    !hasMultiple &&
    !(
      status === AffiliationStatusEnum.NotLogged ||
      status === AffiliationStatusEnum.Onboarding ||
      status === AffiliationStatusEnum.OnboardingDraft ||
      status === AffiliationStatusEnum.Affiliated
    );
  const isNotLoggedIn = status === AffiliationStatusEnum.NotLogged;
  const isOnboardingVisible =
    isoCode === 'be' &&
    (status === AffiliationStatusEnum.Onboarding ||
      status === AffiliationStatusEnum.OnboardingDraft ||
      status === AffiliationStatusEnum.Affiliated);

  // Handler callbacks
  const singleOnboardHandler = useCallback(
    async (selectedContractId?: string) => {
      if (!selectedContractId) await setContractId(contractId);
      gtm.trackClickRegisterLocation();
      routerPush('/onboarding');
    },
    [contractId, setContractId],
  );
  const { handleClick: selectOnboardHandler } = useContractSwitcher(
    t,
    singleOnboardHandler,
    isOnboardingVisible,
    contractId,
    true,
  );
  const { handleClick: selectContractHandler } = useContractSwitcher(
    t,
    singleContractHandler,
    status !== AffiliationStatusEnum.NotLogged,
  );
  const onboardHandler = useCallback(
    () => (hasMultiple ? selectOnboardHandler() : singleOnboardHandler()),
    [hasMultiple, selectOnboardHandler, singleOnboardHandler],
  );
  const createAccountHandler = useCallback(() => {
    gtm.trackClickRegisterCompany();
    signInHandler({ callbackUrl: `${getFullBasePath()}/affiliation`, createAccount: true });
  }, [signInHandler]);

  // Memo
  const notification = useMemo<NotificationType | undefined>(() => {
    switch (status) {
      case AffiliationStatusEnum.AffiliationDraft:
        return {
          description: t(
            'landing_page.status.affiliationDraft',
            'You have not finished your previous registration. Do you want to continue?',
          ),
          onClick: affiliateHandler,
          onDiscard: onDiscardAffiliation,
        };
      case AffiliationStatusEnum.Onboarding:
        return {
          description: t(
            'landing_page.status.onboarding',
            'You have not finished your previous location registration. Do you want to continue?',
          ),
          onClick: onboardHandler,
        };
      case AffiliationStatusEnum.OnboardingDraft:
        return {
          description: t(
            'landing_page.status.onboardingDraft',
            'You have unfinished a location registration. Do you want to continue?',
          ),
          onClick: onboardHandler,
          onDiscard: onDiscardOnboarding,
        };
      default:
        return undefined;
    }
  }, [onboardHandler, onDiscardAffiliation, onDiscardOnboarding, status, t]);

  return (
    <Flex direction="column" alignItems="center" py={py} px={px}>
      {notification && (
        <Box mt={{ base: 0, md: -6 }} mb="12" width="100%">
          <Notification
            description={notification.description}
            isClosable={Boolean(notification.onDiscard)}
            buttonProps={{
              id: 'Landing_continueButton',
              onClick: notification.onClick,
              children: t('landing_page.status.continue', 'Continue'),
            }}
            onClose={notification.onDiscard}
            variant={InteractiveVariants.INTERACTIVE_WARNING}
          />
        </Box>
      )}
      {campaign && <CampaignLogo campaign={campaign} />}
      <TextTitle
        mb={16}
        title={t('landing_page.pageHeader', 'Welcome to merchant portal')}
        subtitle={t(
          'landing_page.subheader',
          'Manage your company or register new one in simple steps',
        )}
      />
      <Stack spacing="8" align="stretch" direction={{ base: 'column', md: 'row' }}>
        {isSelfcareEnabled && (
          <OfferCard
            buttonText={t('landing_page.portal.button', 'Proceed to Portal')}
            descriptionText={t(
              'landing_page.portal.description',
              'Is your company already registered? Manage your company on the portal.',
            )}
            headerText={t('landing_page.portal.header', 'Manage company')}
            id="Landing_portalProceedButton"
            illustrationBg="core.boldyBlue.5" // TODO: plx/semantic/color/illustration/background8
            illustrationId={Illustrations.OFFICE}
            isDisabled={isManageDisabled}
            onClick={hasMultiple ? selectContractHandler : singleContractHandler}
          />
        )}

        <OfferCard
          buttonText={t('landing_page.affiliation.button', 'Proceed to Registration')}
          descriptionText={t(
            'landing_page.affiliation.description',
            'Start accepting Pluxee products by registering your company. It is super easy!',
          )}
          headerText={t('landing_page.affiliation.header', 'Register company')}
          id="Landing_registrationProceedButton"
          illustrationBg="core.veryYellow.3" // TODO: plx/semantic/color/illustration/background9
          illustrationId={Illustrations.OFFICE}
          onClick={isNotLoggedIn ? createAccountHandler : affiliateHandler}
        />
        {isOnboardingVisible && (
          <OfferCard
            buttonText={t('landing_page.onboarding.button', 'Add new location')}
            descriptionText={t(
              'landing_page.onboarding.description',
              'You can simply add a location to already registered company.',
            )}
            headerText={t('landing_page.onboarding.header', 'Register location')}
            id="Landing_onboardingProceedButton"
            illustrationBg="core.darkGreen.4"
            illustrationId={Illustrations.LOCAL_SHOPS}
            onClick={onboardHandler}
          />
        )}
      </Stack>
      {isoCode === 'be' && t('landing_page.legal_disclaimer', '') && (
        <HtmlText as="div" mt={12}>
          {tHtml('landing_page.legal_disclaimer')}
        </HtmlText>
      )}
    </Flex>
  );
};

export default withNamespaces(Landing, 'landing_page');
